const types = {
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  API_START: "API_START",
  API_END: "API_END",
  ACCESS_DENIED: "ACCESS_DENIED",
  API_ERROR: "API_ERROR",
  API: "API",
  SET_USER_JWT: "SET_USER_JWT",
  SHOW_ERROR_MODAL: "SHOW_ERROR_MODAL",
  SET_USERS_DATA: "SET_USERS_DATA",
  SET_WORKLIST_DATA: "SET_WORKLIST_DATA",
  SET_AUDITLOG_DATA: "SET_AUDITLOG_DATA",
  SET_QUERYLOG_DATA: "SET_QUERYLOG_DATA",
  SET_WORKITEM_DATA: "SET_WORKITEM_DATA",
  SET_APP_COUNT: "SET_APP_COUNT",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_DASHBOARD_OPTION: "SET_DASHBOARD_OPTION",
  SET_LICENSE_FORMS_DATA: "SET_LICENSE_FORMS_DATA",
  SET_LICENSE_SECTIONS_DATA: "SET_LICENSE_SECTIONS_DATA",
  SET_LICENSE_FIELDS_DATA: "SET_LICENSE_FIELDS_DATA",
  SET_LICENSE_SUBFIELDS_DATA: "SET_LICENSE_SUBFIELDS_DATA",
  SET_DASHBOARD_EXPORT_DATA: "SET_DASHBOARD_EXPORT_DATA",
  SET_DASHBOARD_DEPARTMENT: "SET_DASHBOARD_DEPARTMENT",
  SET_DASHBOARD_WINGS: "SET_DASHBOARD_WINGS",
  SET_APP_LICENSE_COUNT: "SET_APP_LICENSE_COUNT",
  SET_CHART_COUNT: "SET_CHART_COUNT",
  SET_USER_DATA: "SET_USER_DATA",
  SET_STATUSREPORT_DATA: "SET_STATUSREPORT_DATA",
  SET_USER_DATA_UNAUTH: "SET_USER_DATA_UNAUTH",
  SET_USER_DATA_DEACTIVATED: "SET_USER_DATA_DEACTIVATED",
  SET_APPROVE_DATA: "SET_APPROVE_DATA",
  SET_REJECT_DATA: "SET_REJECT_DATA",
  SET_COMMENT_DATA: "SET_COMMENT_DATA",
  SET_MINSID_DATA: "SET_MINSID_DATA",
  SET_PDF_DATA: "SET_PDF_DATA",
  SET_EDIT_USER: "SET_EDIT_USER",
  SET_RESET_PWD: "SET_RESET_PWD",
  SET_FORWARD_DATA: "SET_FORWARD_DATA",
  SET_BACKWARD_DATA: "SET_BACKWARD_DATA",
  SET_DEPTID_DATA: "SET_DEPTID_DATA",
  SET_LICNSID_DATA: "SET_LICNSID_DATA",
  SET_DEPTDTLS_DATA: "SET_DEPTDTLS_DATA",
  SET_UPDATENSWS_DATA: "SET_UPDATENSWS_DATA",
  SET_CREATE_USER: "SET_CREATE_USER",
  SET_CREATE_WORKFLOW: "SET_CREATE_WORKFLOW",
  SET_DEPMTID_DATA: "SET_DEPMTID_DATA",
  SET_GENOTP_DATA: "SET_GENOTP_DATA",
  SET_APPROVALSREQUEST_DATA: "SET_APPROVALSREQUEST_DATA",
  SET_VALIDATEOTP_DATA: "SET_VALIDATEOTP_DATA",
  SET_LICENSE_COUNT: "SET_LICENSE_COUNT",
  SET_CLARFCTN_DATA: "SET_CLARFCTN_DATA",
  SET_WORKDETAIL_DATA: "SET_WORKDETAIL_DATA",
  SET_FILEDOWNLOAD_DATA: "SET_FILEDOWNLOAD_DATA",
  SET_WORKLISTITEM_DATA: "SET_WORKLISTITEM_DATA",
  RESET_CONFIGURATOR_STATE: "RESET_CONFIGURATOR_STATE",
  SET_WORKITEMFORM_DATA: "SET_WORKITEMFORM_DATA",
  SET_GEXPORTEXCEL_DATA: "SET_GEXPORTEXCEL_DATA",
  SET_LOGOUT: "SET_LOGOUT",
  SET_DASHBOARD_REPORT_DATA: "SET_DASHBOARD_REPORT_DATA",
  SET_NOACTION_DATA: "SET_NOACTION_DATA",
  SET_BULKFILEDOWNLOAD_DATA: "SET_BULKFILEDOWNLOAD_DATA",
  SET_NEXT_OFFICER_NAME: "SET_NEXT_OFFICER_NAME",
  SET_FILTER_DATA: "SET_FILTER_DATA",
  RESET_FILTER_DATA: "RESET_FILTER_DATA",
  SET_FORGOTPASSWORD_DATA: "SET_FORGOTPASSWORD_DATA",
  SET_LICENSE_DATA: "SET_LICENSE_DATA",
  SET_LICENSE_LIST: "SET_LICENSE_LIST",
  SET_ALL_USERS: "SET_ALL_USERS",
  SET_MODULE_DATA: "SET_MODULE_DATA",
  SET_ALL_DEPARTMENTS: "SET_ALL_DEPARTMENTS",
  //  SET_FILTER_USERS:'SET_FILTER_USERS',
  // SET_FILTER_DEPARTMENTS:'SET_FILTER_DEPARTMENTS',
  RESET_WORKLIST_DATA: "RESET_WORKLIST_DATA",
  SET_CAPTCHA_CODE: "SET_CAPTCHA_CODE",
  VALIDATE_CAPTCHA_CODE: "VALIDATE_CAPTCHA_CODE",
  REFRESH_CAPTCHA_CODE: "REFRESH_CAPTCHA_CODE",
  SET_DEPTWNGDTLS_DATA: "SET_DEPTWNGDTLS_DATA",
  SET_ROLE_LIST: "SET_ROLE_LIST",
  SET_MINISTRY_LIST: "SET_MINISTRY_LIST",
  //SET_MINISTRY_LOGO: 'SET_MINISTRY_LOGO',
  SET_USERS_LIST: "SET_USERS_LIST",
  SET_PAYMENT_DATA: "SET_PAYMENT_DATA",
  SET_BULKFILEID_DATA: "SET_BULKFILEID_DATA",
  SET_SUBMITINFO_DATA: "SET_SUBMITINFO_DATA",
  SET_SEEKINFO_DATA: "SET_SEEKINFO_DATA",
  SET_CLAIM_APPLICATION: "SET_CLAIM_APPLICATION",
  SET_REASSIGN_APPLICATION: "SET_REASSIGN_APPLICATION",
  SET_VIEW_WORKFLOW: "SET_VIEW_WORKFLOW",
  SET_EDIT_WORKFLOW: "SET_EDIT_WORKFLOW",
  SET_WORKFLOW_USER: "SET_WORKFLOW_USER",
  SET_VERIFICATION_SCHEDULE: "SET_VERIFICATION_SCHEDULE",
  SET_NOTIFICATION_DETAILS: "SET_NOTIFICATION_DETAILS",
  UPDATE_NOTIFICATION_FLAG: "UPDATE_NOTIFICATION_FLAG",
  SET_LICENSE_DETAILS: "SET_LICENSE_DETAILS",
  SAVE_LICENSE_DETAILS: "SAVE_LICENSE_DETAILS",
  GENERATE_LICENSE_PDF: "GENERATE_LICENSE_PDF",
  SET_DSC_TOKEN: "SET_DSC_TOKEN",
  SET_DSC_CERTIFICATE: "SET_DSC_CERTIFICATE",
  SET_DSC_PDF: "SET_DSC_PDF",
  SET_CHECKLIST_SUBMIT: "SET_CHECKLIST_SUBMIT",
  SET_CHECKLIST_VIEW: "SET_CHECKLIST_VIEW",
  SET_DESIGNATION_LIST: "SET_DESIGNATION_LIST",
  SET_NOTSHEET_DATA: "SET_NOTSHEET_DATA",
  SET_PENDINGTASK_DATA: "SET_PENDINGTASK_DATA",
  SET_LICENSEDETAILED_DATA: "SET_LICENSEDETAILED_DATA",
  SET_DATA_SIGN: "SET_DATA_SIGN",
  SET_SCRUTINY_DATA: "SET_SCRUTINY_DATA",
  // SET_FILTER_SWSID: 'SET_FILTER_SWSID',
  // SET_FILTER_APPID: 'SET_FILTER_APPID',
  SET_REASSIGN_USERLIST: "SET_REASSIGN_USERLIST",
  SET_REASSIGN_WORKLIST: "SET_REASSIGN_WORKLIST",
  SET_PAYMENT_LIST: "SET_PAYMENT_LIST",
  APPROVAL_DOC_FLAG: "APPROVAL_DOC_FLAG",
  SET_FILE_METADATA: "SET_FILE_METADATA",
  GET_REMARK_DRAFT: "GET_REMARK_DRAFT",
  GET_REMARK_DRAFT_FULL: "GET_REMARK_DRAFT_FULL",
  PUSH_REMARK_DRAFT: "PUSH_REMARK_DRAFT",
  //SET_PAYMENT_DETAILS: 'SET_PAYMENT_DETAILS',
  SET_DOCUMENTARY_DTLS: "SET_DOCUMENTARY_DTLS",
  SET_ISSUE_DOCUMENT: "SET_ISSUE_DOCUMENT",
  SET_REGISTRATION_NUMBER: "SET_REGISTRATION_NUMBER",
  SET_SCRUTINY_HISTORY: "SET_SCRUTINY_HISTORY",
  SET_FORM_HISTORY: "SET_FORM_HISTORY",
  RESET_SCRUTINY_HISTORY: "RESET_SCRUTINY_HISTORY",
  SAVE_IDLS_MACHINE_DETAILS: "SAVE_IDLS_MACHINE_DETAILS",
  GET_IDLS_MACHINE_DETAILS: "GET_IDLS_MACHINE_DETAILS",
  SET_SEARCH_ATTRIBUTE: "SET_SEARCH_ATTRIBUTE",
  GET_IDLS_MACHINE_HISTORY: "GET_IDLS_MACHINE_HISTORY",
  SET_AUDIT_DATA: "SET_AUDIT_DATA",
  GET_SCRUTINY_REMARK: "GET_SCRUTINY_REMARK",
  PUSH_SCRUTINY_REMARK: "PUSH_SCRUTINY_REMARK",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  SET_REPORT_LICENSE_STATUS: "SET_REPORT_LICENSE_STATUS",

  SET_COMPLICE_FILTER_DATA: "SET_COMPLICE_FILTER_DATA",
  SET_COMPILICE_USER_JWT: "SET_COMPILICE_USER_JWT",
  RESET_COMPILICE_CONFIGURATOR_STATE: "RESET_COMPILICE_CONFIGURATOR_STATE",
  COMPILIENCEREPORTDATA: "COMPILIENCEREPORTDATA",
  SET_COMPENCEWORKLIST_DATA: "SET_COMPENCEWORKLIST_DATA",
  SET_ORGID_COMP_LIST_DATA: "SET_ORGID_COMP_LIST_DATA",
  SET_APPID_COMP_LIST_DATA: "SET_APPID_COMP_LIST_DATA",
  SET_SWSID_COMP_LIST_DATA: "SET_SWSID_COMP_LIST_DATA",
  RESET_COMPENSEWORKLIST_DATA: "RESET_COMPENSEWORKLIST_DATA",
  SET_COMPLIENCE_WORKITEM_DATA: "SET_COMPLIENCE_WORKITEM_DATA",
  SHOW_SCURITINY: "SHOW_SCURITINY",
  SUSPENSION:'SUSPENSION',
  CANCELLATION: 'CANCELLATION',
  SEEK_INFO: "SEEK_INFO",
  SEND_BACK: "SEND_BACK",
  RAISE_CLARIFICATION: "RAISE_CLARIFICATION",
  INVESTOR_GRP_BTN: "INVESTOR_GRP_BTN",
  POSTSCRUTINY: "POSTSCRUTINY",
  UPDATEREMARKSTATE: "UPDATEREMARKSTATE",
  UPDATE_CONTENT_ID: "UPDATE_CONTENT_ID",
  CLOSESEEKINFO: "CLOSESEEKINFO",
  CLOSE_BACK_FUN: "CLOSE_BACK_FUN",
  INVIDUAL_SCRUTINY: "INVIDUAL_SCRUTINY",
  ADDITIONAL_INFO: "ADDITIONAL_INFO",
  STATUS_ARRAY: "STATUS_ARRAY",
  LISENCE_ARRAY: "LISENCE_ARRAY",
  DEPT_ARRAY:"DEPT_ARRAY",
  LABELS_ARRAY: "LABELS_ARRAY",
  DEPT_WNG_ARRAY: "DEPT_WNG_ARRAY",
  APPLICATIONNUM_ARRAY: "APPLICATIONNUM_ARRAY",
  USERS_ARRAY: "USERS_ARRAY",
  RECEIVED_ARRAY: "RECEIVED_ARRAY",
  STARTDATE_ARRAY: "STARTDATE_ARRAY",
  ENDDATE_ARRAY: "ENDDATE_ARRAY",
  SHOW_BTN:"SHOW_BTN",
  MANDATORYLIST : "MANDATORYLIST",
  SET_EXCEL_UPLOAD : "SET_EXCEL_UPLOAD",
  SET_ADD_MINISTRY_LICENSE_INSERTION: "SET_ADD_MINISTRY_LICENSE_INSERTION",
  SET_ADD_DEPARTMENT_LICENSE_INSERTION: "SET_ADD_DEPARTMENT_LICENSE_INSERTION",
  SET_ADD_DEPARTMENT_WING_LICENSE_INSERTION: "SET_ADD_DEPARTMENT_WING_LICENSE_INSERTION",
  SET_CREATE_LICENSE_INSERTION: "SET_CREATE_LICENSE_INSERTION",

};
export default types;
